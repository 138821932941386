import algoliasearch from 'algoliasearch';
import {algolia_apps} from './config';
import {createNullCache} from "@algolia/cache-common";

let indexAlgolia = {};

let initIndex = (index) => {
    if (!indexAlgolia[index]) {
        const app = algolia_apps?.find(d => d.indexes.includes(index));
        indexAlgolia[index] = algoliasearch(app.id, app.privatekey,{responsesCache:createNullCache(),requestsCache:createNullCache()}).initIndex(index);
    }
}

let algoliaIndex = (index) => {
    initIndex(index);
    return indexAlgolia[index];
}

let algoliaIndexBrowser = algoliaIndex;


export { algoliaIndex,algoliaIndexBrowser }
