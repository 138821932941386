import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import {setObjects} from "../algolia/getObjects";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA9AVCpiggKS2fUl1X_Ow7aUQbpoSB4HhM",
    authDomain: "seguridad-electrica.firebaseapp.com",
    databaseURL: "https://seguridad-electrica.firebaseio.com",
    projectId: "seguridad-electrica",
    storageBucket: "seguridad-electrica.appspot.com",
    messagingSenderId: "488476535600",
    appId: "1:488476535600:web:4077697e76ff087382f6f5"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
var uid;
auth.onAuthStateChanged(u=>uid=u&&u.uid);
const FieldValue = firebase.firestore.FieldValue;
const updateDoc = async ({indexName,id,hit,delete_fields}) => {
    if(!hit)hit={};
    if (delete_fields) for (let i in delete_fields) hit[delete_fields[i]] = firebase.firestore.FieldValue.delete();
    if(uid) hit.lastEditBy=uid;
    await db.collection(indexName).doc(id).update({algoliaUpdated:false,...hit});
    await setObjects(indexName,{[id]:hit},{'update':true});
};
const newDoc = ({indexName,hit}) => {
    if(!hit)hit={};
    if(uid) hit.lastEditBy=uid;
    return db.collection(indexName).add({algoliaUpdated:false,modified_date:firebase.firestore.Timestamp.fromDate(new Date()),...hit});
};
export {db,auth,updateDoc,newDoc,googleAuthProvider,FieldValue}
export default firebase;
