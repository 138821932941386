
// let constants = {
//     algolia : {appID:"SHAO80QXS7", apiKey: "bb97847d517911ab1f469099635bb674",
//         // familias : { appID:"N2PRB36KF8", apiKey: "ed2b6db0927649bee0aa2c9cf6a11daa" } ,
//         // normas : { appID:"8GI75F4499", apiKey: "9c8c79712c37f802f95e36de2a1b25f7" } ,
//         // normasgeneral : { appID:"8GI75F4499", apiKey: "9c8c79712c37f802f95e36de2a1b25f7" },
//         // notas : { appID:"D1E6XNNQ1F", apiKey: "cc61d9c1b1c8947968752103c0bf7e92" },
//         // certificados : { appID:"DX1PBZPWOJ", apiKey: "7ec56c1e9be0b6bb16ebaeb9168540d0" },
//         // cotizaciones : { appID:"SHAO80QXS7", apiKey: "bb97847d517911ab1f469099635bb674" }, //mod
//         // trabajos : { appID:"DRA9K4FVQA", apiKey: "a3b012e9bbc626e02662ceb603a2f791" },
//         // listasdeprecios : { appID:"DRA9K4FVQA", apiKey: "a3b012e9bbc626e02662ceb603a2f791" },
//         // procesos : { appID:"SHAO80QXS7", apiKey: "bb97847d517911ab1f469099635bb674" }, //mod
//         // inspecciones : { appID:"W0FWYIKO3V", apiKey: "d7cbc3b9005e7f14882240181e0840cb" },
//         // clientes: { appID:"8TOOQUEPEF", apiKey:"d648dfffa708f27e67b37a8d62f7a848" },
//         // fabricas: { appID:"L6I7PIA60D", apiKey:"d24bcca1dc072f050bcb9424b976f610" },
//         // ensayos: { appID:"V1NEK0D1M9", apiKey:"6d5dbf41336f78cdc2f436e31523f032" },
//         // tad: { appID:"ZWRGSM3TN8", apiKey: "07b68a4010a0feaccd2bf8faf268bb64" },
//         // productosGAS: { appID:"G6M2V0VBZ2", apiKey: "215b1107cd7039692f169e7560292df3" },
//         browse : { appID:"XKGYRIUNNK", apiKey: "06cdef3db9d676a1bb04216fd2f8a270",
//             normas : { appID:"8GI75F4499", apiKey: "9c8c79712c37f802f95e36de2a1b25f7" } ,
//             normasgeneral : { appID:"8GI75F4499", apiKey: "9c8c79712c37f802f95e36de2a1b25f7" },
//             familias : { appID:"N2PRB36KF8", apiKey: "4c9007cd038577d80535de8c54167627" } ,
//             certificados : { appID:"DX1PBZPWOJ", apiKey: "7e7778c638c1bd5de3ea95efc85ab59d" },
//             cotizaciones : { appID:"DRA9K4FVQA", apiKey: "c7a69a1a0c561e98863e20bc47bac60c" },
//             trabajos : { appID:"DRA9K4FVQA", apiKey: "c7a69a1a0c561e98863e20bc47bac60c" },
//             listasdeprecios : { appID:"DRA9K4FVQA", apiKey: "c7a69a1a0c561e98863e20bc47bac60c" },
//             notas : { appID:"D1E6XNNQ1F", apiKey: "cc61d9c1b1c8947968752103c0bf7e92" },
//             procesos : { appID:"0ZLRSB0AFX", apiKey: "7a0f9d107d1e33f469c552c5111624be" },
//             clientes: { appID:"8TOOQUEPEF", apiKey:"d1029a00905fd960d93dfbf55433417d" },
//             fabricas: { appID:"L6I7PIA60D", apiKey:"82a9fb1a7cd76b28fb26c31137b4247b" },
//             ensayos: { appID:"V1NEK0D1M9", apiKey:"7b49b33fb350b4a5bf11804243b269df" },
//             tad: { appID:"ZWRGSM3TN8", apiKey: "5a72cecd7ee158f5f5a1dddad2bac499" },
//         }
//     }
// }

export const algolia_apps = [
    {
        "id": "ZS6KW3H19H",
        "privatekey": "64dffa037553542b95e8b3a50771b659",
        "indexes": [
            "areas",
            "laboratorios",
            "employees",
            "goods",
            "certificados",
            "roles",
            "consultores",
            "inspecciones",
            "etiquetas"
        ]
    },
    {
        "id": "SHAO80QXS7",
        "privatekey": "51bd69197d07d4ad7425fc2112517352",
        "indexes": [
            "clientes",
            "users",
            "familias",
            "rubro",
            "contactos",
            "notas",
            "fabricas",
            "normasgeneral",
            "normas",
            "cotizaciones"
        ]
    },
    {
        "id": "HIF7RJQXNP",
        "privatekey": "a32c818519c0641261ec94b6dfae39d0",
        "indexes": [
            "config",
            "ensayos",
            "origen",
            "plantillas",
            "qr",
            "indices",
            "listasdeprecios",
            "trabajos",
            "procesos",
            "tad"
        ]
    },
    {
        "id": "DQUHT6Z1TT",
        "privatekey": "dc028ba9938745388fd24df9542f13c2",
        "indexes": [
            "productosGAS",
            "catalogo"
        ]
    }
]
